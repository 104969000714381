/*
 * Copyright (C) Motorola Solutions, INC.
 * All Rights Reserved.
 */

console.everything = [];

const now = () => new Date().toISOString();

function interceptLog(logType) {
    const original = console[logType].bind(console);
    return function () {
        console.everything.push({
            type: logType.toUpperCase(),
            timeStamp: now(),
            value: Array.from(arguments)
                .map((arg) => arg && arg.name && arg.message ? [arg.name, arg.message, arg.stack].flat().filter((val) => !!val).join(' ') : arg)
                .join(' ')
        });
        original.apply(console, arguments);
    };
}
['log', 'error', 'warn', 'debug', 'info'].forEach((logType) => (console[logType] = interceptLog(logType)));
